



























import { Component, Vue } from 'vue-property-decorator';
import { Timeline } from 'vue-tweet-embed';

@Component({
    components: {
        Timeline
    }
})
export default class TwitterTimeline extends Vue {
    private isLoaded = false;

    public mounted() {
        // Timelineのデフォルトの機能だと読み込み完了にラグがあるため、
        // タイムラインとSkeletonLoaderが一瞬同時に表示されてしまうので、
        // こちらでwatchして表示を遅らせてやる
        this.$watch('$refs.timeline.isLoaded', () => {
            setTimeout(() => {
                if (this.$refs.timeline) {
                    this.isLoaded = (this.$refs.timeline as typeof Timeline).isLoaded;
                }
            }, 500);
        }, { deep: true });
    }
}
