











































import { Component, Vue } from 'vue-property-decorator';

import Profile from '@/components/Profile.vue';
import MainProduct from '@/components/MainProduct.vue';
import TwitterTimeline from '@/components/TwitterTimeline.vue';

type Link = {
    name: string;
    icon: string;
    link: string;
};

@Component({
    components: {
        Profile,
        MainProduct,
        TwitterTimeline
    },
    metaInfo: {
        title: 'MT\'s Craft Atelier',
        titleTemplate: undefined,
        meta: [
            { name: 'description', content: 'MTのホームページ' },
            { name: 'og:type', content: 'website' },
            { name: 'og:title', content: 'MT\'s Craft Atelier' },
            { name: 'og:description', content: 'MTのホームページ' }
        ]
    }
})
export default class Home extends Vue {
    private links: Link[] = [
        { name: 'Twitter', icon: 'twitter', link: 'https://twitter.com/MT224244' },
        { name: 'GitHub', icon: 'github', link: 'https://github.com/MT224244' },
        { name: 'niconico', icon: 'niconico', link: 'https://www.nicovideo.jp/user/21322282' },
        { name: 'YouTube', icon: 'youtube', link: 'https://www.youtube.com/channel/UCY2KdG7hCXqzlI8SaNpC6YA' },
        { name: 'ScriptArts', icon: 'scriptarts', link: 'https://www.scriptarts.jp/' }
    ];

    /**
     * Parallax用
     */
    private scrollTop = 0;

    private onScroll() {
        this.scrollTop = document.documentElement.scrollTop;
    }

    public mounted() {
        this.scrollTop = document.documentElement.scrollTop;
    }
}
