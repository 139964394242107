

















































import { Component, Watch, Vue } from 'vue-property-decorator';

import { WorksData } from '@/scripts/WorksData';

// https://qiita.com/yarnaimo/items/e92600237d65876f8dd8
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function chunk<T extends any[]>(arr: T, size: number) {
    return arr.reduce(
        (newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]),
        [] as T[][]
    );
}

@Component({
    metaInfo: {
        title: 'Works',
        meta: [
            { name: 'description', content: '制作したもの' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: 'MT\'s Craft Atelier / Works' },
            { name: 'og:description', content: '制作したもの' }
        ]
    }
})
export default class Works extends Vue {
    private readonly MAX_PAGE_ITEMS = 20;

    private currentPage = 1;
    private selectedTag = '';
    private tags = WorksData.map(x => x.tags).flat();
    private works = chunk(WorksData, this.MAX_PAGE_ITEMS);

    /**
     * クエリパラメータのpage
     * @return ページ番号 無効な値なら1を返す
     */
    private get queryPage() {
        if (this.$route.query.page) {
            return parseInt(this.$route.query.page as string) || 1;
        }
        else {
            return 1;
        }
    }

    /**
     * クエリパラメータのtag
     * @return 絞り込みタグ 無ければ空文字を返す
     */
    private get queryTag() {
        return this.$route.query.tag as string || '';
    }

    @Watch('currentPage')
    private currentPage_onWatch() {
        if (this.currentPage < 1) this.currentPage = 1;

        this.routeing();
    }

    @Watch('selectedTag')
    private selectedTag_onWatch() {
        // Comboboxをクリアするとundefinedになるので空文字を代入
        if (!this.selectedTag) this.selectedTag = '';

        if (this.selectedTag === '') {
            this.works = chunk(WorksData, this.MAX_PAGE_ITEMS);
        }
        else {
            this.works = chunk(WorksData.filter(work => {
                return work.tags.includes(this.selectedTag);
            }), 10);
        }

        if (this.works.length < 1) {
            this.currentPage = 1;
        }
        else if (this.currentPage > this.works.length) {
            this.currentPage = this.works.length;
        }

        this.routeing();
    }

    @Watch('$route')
    private $route_onWatch() {
        this.currentPage = this.queryPage;
        this.selectedTag = this.queryTag;
    }

    /**
     * タグボタン押下時
     */
    private btnTag_onClick(tag: string) {
        if (this.selectedTag === tag) return;

        this.currentPage = 1;
        this.selectedTag = tag;
    }

    /**
     * ルーティング
     */
    private routeing() {
        if (this.queryPage === this.currentPage && this.queryTag === this.selectedTag) return;

        this.$router.push({
            path: '/works',
            query: {
                ...this.$route.query,
                page: this.currentPage.toString(),
                tag: this.selectedTag === '' ? undefined : this.selectedTag
            }
        });
        document.documentElement.scrollTop = 0;
    }

    public mounted() {
        if (!this.$route.query.page) {
            this.$router.replace({
                path: '/works',
                query: { ...this.$route.query, page: '1' }
            });
        }
        this.currentPage = this.queryPage;
        this.selectedTag = this.queryTag;
    }
}
