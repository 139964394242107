type Work = {
    name: string;
    createdDate: string;
    imageSrc: string;
    description: string;
    productLink?: string;
    tags: string[];
};

export const WorksData: Work[] = [
    {
        name: 'ドラちゃんのチートシート',
        createdDate: '2021/5/6',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/117219910-2ca43b80-ae41-11eb-9490-08b3e2a274b3.png',
        description: '｢プリンセスコネクト！Re:Dive｣のミニゲーム｢大激戦！しりとりドラゴンズ｣のチートシート',
        productLink: 'https://shiritori.priconner.mt224244.com',
        tags: [
            'プリコネR',
            'Web',
            'ツール',
            'TypeScript',
            'Node.js',
            'Vue.js',
            'Quasar'
        ]
    },
    {
        name: 'MC Playsound',
        createdDate: '2021/2/21',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/108615497-34f6f800-7448-11eb-8168-2672b95fe4a5.png',
        description: 'Minecraftのサウンドリソースを検索、再生するツール',
        productLink: 'https://github.com/MT224244/mc-playsound',
        tags: [
            'Minecraft',
            'ツール',
            'TypeScript',
            'Node.js',
            'Vue.js',
            'Quasar',
            'Electron'
        ]
    },
    {
        name: 'MT\'s Craft Atelier',
        createdDate: '2020/10/9',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/95470010-3ce35d80-09bb-11eb-87a7-9c4d2626ad7d.png',
        description: 'このサイト',
        tags: [
            'Web',
            'TypeScript',
            'Node.js',
            'Vue.js',
            'Vuetify'
        ]
    },
    {
        name: 'TUSB ProgressViewer',
        createdDate: '2020/1/19',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/78503623-cadb5300-77a2-11ea-9552-c48ea654ef3a.png',
        description: 'The Unusual SkyBlockの攻略進捗を確認するツール',
        productLink: 'https://github.com/ScriptArts/TUSB_ProgressViewer',
        tags: [
            'Minecraft',
            'ツール',
            'C#',
            'WPF'
        ]
    },
    {
        name: 'WPF ColorPicker',
        createdDate: '2019/4/29',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/56893828-006dbb80-6abf-11e9-80fb-6412c6acde2b.png',
        description: 'WPF用のカラーピッカーコントロール',
        productLink: 'https://github.com/MT224244/WpfColorPicker',
        tags: [
            'ライブラリ',
            'C#',
            'WPF'
        ]
    },
    {
        name: 'LifeGame データパック',
        createdDate: '2018/9/11',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/75110771-b40af200-5675-11ea-812e-f2ff993b9e42.png',
        description: 'Minecraftのコマンドでライフゲームを作りました',
        productLink: 'https://github.com/MT224244/datapack_lifegame',
        tags: [
            'Minecraft',
            'データパック',
            'mcfunction'
        ]
    },
    {
        name: 'AVA CANNON 再現マップ',
        createdDate: '2016/11/30',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/75098061-1cfe5580-55f5-11ea-8ebe-4de9b662ba6b.png',
        description: 'Alliance of Valiant ArmsというゲームのCANNONというマップをMinecraftで再現しました',
        productLink: 'https://github.com/MT224244/MCMap_AVA_CANNON',
        tags: [
            'Minecraft',
            '配布マップ'
        ]
    },
    {
        name: 'AVA Gallery 再現マップ',
        createdDate: '2015/11/30',
        imageSrc: 'https://user-images.githubusercontent.com/25514849/75092648-4c916b80-55bd-11ea-9fa4-8fecf0925aa8.png',
        description: 'Alliance of Valiant ArmsというゲームのGalleryというマップをMinecraftで再現しました',
        productLink: 'https://github.com/MT224244/MCMap_AVA_Gallery',
        tags: [
            'Minecraft',
            '配布マップ'
        ]
    }
];
