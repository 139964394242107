

























































import { Component, Vue } from 'vue-property-decorator';

type Skill = {
    name: string;
    icon?: string;
    backgroundColor?: string;
    foreColor?: string;
    link: string;
};

@Component({
    metaInfo: {
        title: 'About',
        meta: [
            { name: 'description', content: 'このサイトについて' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: 'MT\'s Craft Atelier / About' },
            { name: 'og:description', content: 'このサイトについて' }
        ]
    }
})
export default class About extends Vue {
    private skills: Skill[] = [
        {
            name: 'TypeScript',
            icon: 'language-typescript',
            foreColor: '#ffffff',
            backgroundColor: '#007acc',
            link: 'https://www.typescriptlang.org/'
        },
        {
            name: 'Node.js',
            icon: 'nodejs',
            foreColor: '#68a063',
            backgroundColor: '#303030',
            link: 'https://nodejs.org/ja/'
        },
        {
            name: 'Electron',
            icon: 'electron-framework',
            foreColor: '#9feaf9',
            backgroundColor: '#2b2e3b',
            link: 'https://www.electronjs.org/'
        },
        {
            name: 'Vue.js',
            icon: 'vuejs',
            foreColor: '#42b883',
            backgroundColor: '#35495e',
            link: 'https://jp.vuejs.org/'
        },
        {
            name: 'C#',
            icon: 'dot-net',
            foreColor: 'white',
            backgroundColor: '#512bd4',
            link: 'https://docs.microsoft.com/ja-jp/dotnet/csharp/'
        },
        {
            name: 'WPF',
            icon: 'dot-net',
            foreColor: 'white',
            backgroundColor: '#512bd4',
            link: 'https://docs.microsoft.com/ja-jp/dotnet/desktop/wpf/?view=netdesktop-5.0'
        }
    ];
}
