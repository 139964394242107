



























import { Component, Vue } from 'vue-property-decorator';

import { WorksData } from '@/scripts/WorksData';

@Component
export default class MainProduct extends Vue {
    private product = WorksData.find(x => x.name === 'TUSB ProgressViewer');

    private btnTag_onClick(tag: string) {
        this.$router.push({
            path: '/works',
            query: { tag }
        });
        document.documentElement.scrollTop = 0;
    }
}
