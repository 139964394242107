









import { Component, Vue } from 'vue-property-decorator';

@Component({
    metaInfo: {
        title: '404 Not Found',
        meta: [
            { name: 'robots', content: 'noindex' },
            { name: 'description', content: 'ページが見つかりません' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: 'MT\'s Craft Atelier / 404 Not Found' },
            { name: 'og:description', content: 'ページが見つかりません' }
        ]
    }
})
export default class NotFound extends Vue {}
